$('#slick_cat_miniature').slick({
	dots: false,
	infinite: true,
	speed: 300,
	slidesToShow: 1,
	slidesToScroll: 1,
	// variableWidth: true,
	// rows: 0,
	prevArrow:"<button type=\"button\" class=\"btn btn-link slick-prev slick-arrow\"><span class=\"icons-left\"></span></button>",
	nextArrow:"<button type=\"button\" class=\"btn btn-link slick-next slick-arrow\"><span class=\"icons-right\"></span></button>",
  });

  /** --------------------  */

$(document).ready(function () {
	var total_item = $("#subcategories-list .category_miniature").length;
	var items_per_page = $("#subcategories-list").data('cat-per-page');
	if (total_item > items_per_page){
		$('#subcategories-list').paginate({
			items_per_page: items_per_page,
			prev_text: 'Page précédente',
			next_text: 'Page suivante'
		});

		$( '<span class="show_all" id="show_all">Afficher tout</span>' ).insertAfter( "#subcategories-list" );

		$(document).on("click", "#show_all" ,function () {
			$("#subcategories-list .category_miniature").removeAttr('style');
		});
	}
});

$('.anchorLink').click(function(){
    $('html, body').animate({
        scrollTop: $( $(this).attr('href') ).offset().top
    }, 500);
    return false;
});

$(document).ready(function () {
	var total_item = $(".cms_realisations .elementor-image-gallery .ce-gallery-item").length;
	var items_per_page = 16;
	if (total_item > items_per_page){
		$('.cms_realisations .elementor-image-gallery').paginate({
			items_per_page: items_per_page,
			prev_text: 'Page précédente',
			next_text: 'Page suivante'
		});
		$( '<span class="show_all" id="show_all">Afficher tout</span>' ).insertAfter( ".cms_realisations .elementor-image-gallery" );

		$(document).on("click", "#show_all" ,function () {
			$(".cms_realisations .elementor-image-gallery .ce-gallery-item").removeAttr('style');
		});
	}
});